<template>

<div class="page-wrapper">

    <!-- Page header end -->
    <b-col lg="6" class="my-1 float-right">
      <b-form-group
        label="Search"
        label-for="filter-input"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>

        </b-input-group>
      </b-form-group>
    </b-col>

    <b-table
      bordered
      hover
      :items="items"
      :fields="fields"
      :filter="filter"
      v-bind="$attrs"
      v-on="$listeners"
      small
      :tbody-tr-class="rowClass"
      thead-tr-class="bms-table-thead"
      class="bms-table"
      selectable
      @row-clicked="onRowSelected"
    > 
      <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
        <slot :name="slotName" v-bind="scope"/>
      </template>

      <template v-slot:custom-foot="data">
        <tr v-if="data.fields.some((field) => field.footer)">
            <th v-for="field in data.fields" :key="field.key">
                <span v-if="field.footer">{{ field.footer(field, data.items) }}</span>
            </th>
        </tr>
      </template>

    </b-table>


  </div>

</template>

<script>

export default {
  props: {
    fields: Array,
    items: Array,
    urlBuilder: Function
  },
  data() {
    return {
      filter: null
    }
  },
  methods: {
    rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item.deleted == true || item.deactivated == true) {
          return 'bms-table-tr-deactivated'
        }
        return 'bms-table-tr'
  },
    onRowSelected(record) {
      this.$router.push(this.$props.urlBuilder(record))
    }
}
}

</script>
<style scoped>
/deep/ .bms-table-tr td {
  vertical-align: middle;
  padding-top: 0rem;
  padding-right: 1rem;
  padding-bottom: 0rem;
  padding-left: 1rem;
  height: 2.5rem;
}
/deep/ .bms-table-tr-deactivated td {
  vertical-align: middle;
  padding-top: 0rem;
  padding-right: 1rem;
  padding-bottom: 0rem;
  padding-left: 1rem;
  background-color: #e5e5e5;
  font-style: italic;
  height: 3rem;
}
/deep/ .bms-table-thead {
  background-color: var(--primary);
  color: #fff;
}
/deep/ .bms-table-thead th tr{
  padding-top: .1rem;
  padding-right: 1rem;
  padding-bottom: .1rem;
  padding-left: 1rem;
  font-weight: 300;
  border-bottom: 2px solid #e0e3ec;
  border-bottom-color: rgb(224, 227, 236);
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
/deep/ .bms-table {
  font-size: .85rem;
}
</style>
