<template>
<div>
    <!-- Breadcrumb start -->
      <ol class="breadcrumb">
          <li class="breadcrumb-item">Quotes</li>
      </ol>
      <!-- Breadcrumb end -->

      <!-- App actions start -->
      <b-button
        to="quotes/new"
        class="btn btn-success float-left">
        <b-icon icon="plus-circle" />
        Add Quote
      </b-button>
  <BMSTable
    :items="items"
    :fields="fields"
    sort-by="id"
    :sort-desc='true'
    :urlBuilder="buildUrl"
  >
    <template #cell(actions)="row">
      <b-button-group>
        <b-button @click="openPDF(row.item.id)" variant="primary" v-if="row.item.status != 'OPEN'">
          <b-icon icon="download" />
        </b-button>
      </b-button-group>
    </template>
  </BMSTable>
</div>
</template>

<script>

import { getAllQuotes } from '@/api/quotes.api'
import { generateQuote } from '@/api/quotes.api'
import BMSTable from '@/components/BMSTable';
import crudMixin from '@/api/crud'

export default {
    mixins: [crudMixin],
    data() {
        return {
            fields: [{
                key: 'id',
                label: '#',
                sortable: true,
            }, {
                key: 'client.company',
                label: 'Company',
                sortable: true,
                thClass: 'widthCompany'
            }, {
                key: 'description',
                label: 'Description',
                sortable: true,
            }, {
                key: 'opened',
                label: 'Opened',
                sortable: true,
                thClass: 'widthOpened',
            }, {
                key: 'status',
                label: 'Status',
                sortable: true,
            }, {
                label: 'Action',
                key: 'actions'
            }],
            items: null,
            filter: null
        }
    },
    mounted() {
        this.getQuotes();
    },
    methods: {
        openPDF(id) {
            this.call(
                generateQuote(id),
                (res) => {
                    window.open(res.data.url, "_blank");
                }
            )
        },
        getQuotes() {
            this.call(
                getAllQuotes(),
                (res) => {
                    this.items = res.data.quotes
                }
            )
        },
        buildUrl(quote){
            return { name: 'Quote', params: { id: quote.id }}
    }
    },
    components: {
        BMSTable
    }
}

</script>
<style>
    .widthOpened{
        width: 10% !important;
    }
    .widthCompany{
        width: 20% !important;
    }
</style>
